import { Node } from 'Types/types';
import { getIconSourceURL, getNodeIconElement } from 'Map/Graph/Icons';
import { PieChart, Pie, Cell, Label } from 'recharts';
import { useMutation, useQuery } from '@apollo/client';
import {
    ADD_TARGETS_TO_POLICY_PROFILE,
    LIST_POLICY_PROFILES,
    REMOVE_TARGETS_FROM_POLICY_PROFILE,
} from 'Graph/typedQueries';
import { useTenant } from 'Hooks/Hooks';
import { useMemo, useState } from 'react';
import { ListPolicyProfilesQuery, PolicyAction } from 'GeneratedGQL/graphql';
import { GET_ENTITIES_BY_TYPE_AS_NODES } from 'Graph/queries';
import { BackendNode } from 'Map/Graph/Data';
import { Controller, useForm } from 'react-hook-form';
import { SelectedNavItem } from '../PolicyModalTypes';
import { classNames, getDisplayName } from 'Utilities/utils';
import Select from 'react-select';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Tooltip } from 'Library/Tooltip';

type AdaptiveTrustPolicyProps = {
    selectedTarget: Node | undefined;
    selectedTargetId: string | undefined;
    setSelectedNavItem: React.Dispatch<React.SetStateAction<SelectedNavItem | undefined>>;
};

type TrustProfile = ListPolicyProfilesQuery['listPolicyProfiles'][number];

const now = new Date().getTime();

type AdaptiveTrustPolicyFormData = {
    policyProfileId: string;
    targetId: string;
    threshold: number;
    action: PolicyAction;
};

type BoundAdaptiveTrustPolicy = {
    target: BackendNode;
    targetId: string;
    trustProfileId: string;
    trustProfile: TrustProfile;
    threshold?: number;
    action?: PolicyAction;
};

export const AdaptiveTrustPolicy = ({ setSelectedNavItem }: AdaptiveTrustPolicyProps): JSX.Element => {
    const tenantId = useTenant();

    const [addTargetsToPolicyProfile, { loading: loadingAddTargetsToPolicyProfile }] =
        useMutation(ADD_TARGETS_TO_POLICY_PROFILE);

    const { data: dataTrustProfiles, loading: loadingTrustProfiles } = useQuery(LIST_POLICY_PROFILES, {
        variables: { tenantId: tenantId || '' },
    });

    const { data: dataTargets, loading: loadingTargets } = useQuery(GET_ENTITIES_BY_TYPE_AS_NODES, {
        variables: {
            tenantId,
            entityType: 'STATS_ENTITY_TYPE_TARGET',
            permissionsOnly: false,
            dateInMs: now,
        },
    });

    const boundTrustPolicies = useMemo(() => {
        if (dataTrustProfiles && dataTargets) {
            const targetsById: Record<string, BackendNode> = {};

            if (!dataTargets.getEntitiesByTypeAsNodes.nodes) return [];

            dataTargets.getEntitiesByTypeAsNodes.nodes.map((target: BackendNode) => {
                targetsById[target.nodeId] = target;
            });

            const profiles: BoundAdaptiveTrustPolicy[] = [];

            dataTrustProfiles.listPolicyProfiles.map((profile) => {
                profile.targets.map((target) => {
                    if (target) {
                        const backendTarget = targetsById[target.targetId];
                        if (backendTarget) {
                            profiles.push({
                                target: targetsById[target.targetId],
                                targetId: target.targetId,
                                trustProfileId: profile.profileId,
                                trustProfile: profile,
                                threshold: target.threshold,
                                action: target.action,
                            });
                        }
                    }
                });
            });

            console.log('Bound trust policies:', profiles);

            return profiles;
        }
    }, [dataTargets, dataTrustProfiles]);

    const profileId = useMemo(() => {
        if (!dataTrustProfiles?.listPolicyProfiles) {
            return;
        }

        if (dataTrustProfiles?.listPolicyProfiles.length > 0) {
            return dataTrustProfiles?.listPolicyProfiles[0].profileId;
        }
    }, [dataTrustProfiles]);

    const targetOptions = useMemo(() => {
        if (!dataTargets) return [];

        if (!dataTargets.getEntitiesByTypeAsNodes.nodes) return [];

        const validTargets = dataTargets.getEntitiesByTypeAsNodes.nodes.filter((target: BackendNode) => {
            // Filter out nodes we don't want to build policies for
            if (target.props.displayName === '') {
                return false;
            }
            if (target.props.displayName === 'any') {
                return false;
            }
            if (target.nodeType === 'NODE_TYPE_MAILBOX') {
                return false;
            }
            if (target.nodeType === 'NODE_TYPE_GROUP') {
                return false;
            }
            if (target.nodeType === 'NODE_TYPE_FILE') {
                return false;
            }

            // Keep all other nodes
            return true;
        });

        return validTargets
            .map((target: BackendNode) => {
                return { value: target.nodeId, label: target.props.displayName };
            })
            .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));
    }, [dataTargets]);

    const onSubmit = (data: AdaptiveTrustPolicyFormData) => {
        console.log('Adding trust policy:', data);

        addTargetsToPolicyProfile({
            variables: {
                tenantId: tenantId || '',
                policyProfileId: data.policyProfileId,
                targets: [{ targetId: data.targetId, options: [], threshold: data.threshold, action: data.action }],
            },
            refetchQueries: ['listPolicyProfiles'],
            awaitRefetchQueries: true,
        });

        setUpdating(false);
        setAdding(false);
    };

    const {
        register,
        reset,
        handleSubmit,
        formState: { isDirty },
        control,
    } = useForm<AdaptiveTrustPolicyFormData>({
        defaultValues: { threshold: 75, action: PolicyAction.PolicyActionEnforce },
    });

    const loading = loadingTrustProfiles || loadingTargets || loadingAddTargetsToPolicyProfile;

    const edit = (boundTrustPolicy: BoundAdaptiveTrustPolicy) => {
        setUpdating(true);
        setSelectedNavItem({ type: 'target' });
        reset({
            policyProfileId: boundTrustPolicy.trustProfileId,
            targetId: boundTrustPolicy.targetId,
            threshold: boundTrustPolicy.threshold || 75,
            action: boundTrustPolicy.action || PolicyAction.PolicyActionMonitor,
        });

        // scroll to top of adaptive-trust div
        const adaptiveTrustDiv = document.getElementById('adaptive-trust');
        adaptiveTrustDiv?.scrollIntoView({ behavior: 'smooth' });
    };

    const add = () => {
        setAdding(true);
        setUpdating(false);
        reset({
            policyProfileId: dataTrustProfiles?.listPolicyProfiles[0].profileId,
            threshold: 75,
            action: PolicyAction.PolicyActionIscWorkflow,
        });
    };

    const [updating, setUpdating] = useState(false);
    const [adding, setAdding] = useState(false);

    return (
        <div className="p-4" id="adaptive-trust">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <div className="flex items-center space-x-2">
                    <p className="uppercase tracking-wider font-bold text-xs text-gray-400">
                        Where To Detect Untrusted Access
                    </p>
                    {loading && <span className="h-4 w-4 loader" />}
                </div>
            </div>
            <div className="text-xs text-gray-400 pt-2">
                For each access to the target, the Trust Profile will be evaluated and if the Trust Score is below the
                threshold the action will be taken
            </div>

            {!loading && !profileId && (
                <div className="pt-2 text-xs">Trust Profile not found - please contact support.</div>
            )}

            {!updating && !adding && profileId && (
                <div
                    className="p-4 rounded-md bg-gray-700 mt-4 text-gray-500 hover:text-gray-200 text-xs cursor-pointer font-semibold"
                    onClick={add}
                >
                    <p className="">Add Another Target +</p>
                </div>
            )}
            {(updating || adding) && (
                <div className="p-4 rounded-md bg-gray-700 mt-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="space-y-4">
                            <div>
                                <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1">Choose Target</h4>
                                <Controller
                                    name="targetId"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            isDisabled={updating}
                                            classNamePrefix="select-sm"
                                            className="w-full"
                                            name="dataType"
                                            options={targetOptions}
                                            value={targetOptions.find(
                                                (option: { value: string; label: string }) => option.value === value,
                                            )}
                                            onChange={(option) => onChange(option.value)}
                                        />
                                    )}
                                />
                            </div>

                            {/* <div>
                                <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1">Choose Profile</h4>
                                <select
                                    className="w-full input-gray text-xs rounded-none"
                                    required
                                    disabled={updating}
                                    {...register('policyProfileId')}
                                >
                                    <option value="">Select a Profile</option>
                                    <hr />
                                    {dataTrustProfiles &&
                                        dataTrustProfiles.listPolicyProfiles.map((profile) => {
                                            return (
                                                <option key={profile.profileId} value={profile.profileId}>
                                                    {profile.displayName}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div> */}

                            <div>
                                <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1">Threshold</h4>
                                <input
                                    type="number"
                                    min={0}
                                    max={100}
                                    className="w-full input-gray text-xs"
                                    {...register('threshold')}
                                    required
                                />

                                {/* <div className="relative mb-6">
                                    <input
                                        id="labels-range-input"
                                        type="range"
                                        min="0"
                                        max="100"
                                        className="w-full h-2 bg-gray-800 rounded-lg appearance-none cursor-pointer dark:bg-gray-600 text-blue-800"
                                        {...register('threshold')}
                                        required
                                    />
                                    <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-1/4 -bottom-6">
                                        Low
                                    </span>
                                    <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
                                        Medium
                                    </span>
                                    <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-3/4 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
                                        High
                                    </span>
                                </div> */}
                            </div>

                            <div>
                                <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1">Action</h4>
                                <select className="w-full input-gray text-xs" {...register('action')} required>
                                    <option value={PolicyAction.PolicyActionDisable}>Disable</option>
                                    {/* <option value={PolicyAction.PolicyActionMonitor}>Monitor</option>
                                    <option value={PolicyAction.PolicyActionAlert}>Alert</option> */}
                                    <option value={PolicyAction.PolicyActionIscWorkflow}>
                                        Send to Identity Security Cloud Workflow
                                    </option>
                                </select>
                            </div>

                            <div className="flex space-x-2 mt-4">
                                <button
                                    className={classNames(
                                        'btn text-xs rounded-md',
                                        isDirty ? 'btn-primary' : 'btn-disabled',
                                        loading ? 'btn-disabled' : '',
                                    )}
                                    disabled={!isDirty || loading}
                                    type="submit"
                                >
                                    {updating ? 'Update Policy' : 'Add Policy'}
                                </button>

                                <button
                                    className={classNames(
                                        'btn text-xs rounded-md',
                                        loadingAddTargetsToPolicyProfile ? 'btn-disabled' : '',
                                    )}
                                    disabled={loadingAddTargetsToPolicyProfile}
                                    onClick={() => {
                                        setUpdating(false);
                                        setAdding(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}

            <div>
                {boundTrustPolicies?.map((boundTrustPolicy) => {
                    return (
                        <TrustPolicyCard
                            {...boundTrustPolicy}
                            onEdit={edit}
                            key={`${boundTrustPolicy.targetId}.${boundTrustPolicy.trustProfileId}${boundTrustPolicy.threshold}`}
                        />
                    );
                })}
            </div>
            {}
        </div>
    );
};

export const policyActionLookup: Record<PolicyAction, string> = {
    [PolicyAction.PolicyActionMonitor]: 'Monitor',
    [PolicyAction.PolicyActionAlert]: 'Alert',
    [PolicyAction.PolicyActionEnforce]: 'Enforce',
    [PolicyAction.PolicyActionIscWorkflow]: 'Send to Identity Security Cloud Workflow',
    [PolicyAction.PolicyActionDisable]: 'Disable',
    [PolicyAction.PolicyActionUnknown]: 'Unknown',
};

const TrustPolicyCard = ({
    target,
    targetId,
    trustProfile,
    threshold,
    action,
    onEdit,
}: BoundAdaptiveTrustPolicy & { onEdit: (boundTrustPolicy: BoundAdaptiveTrustPolicy) => void }) => {
    const pieColor = 'rgb(12 92 151)';
    const pieSlices = [
        { value: threshold, color: pieColor },
        { value: 100 - (threshold || 0), color: 'rgb(31 41 55)' },
    ];

    const tenantId = useTenant();

    const [removeTargetsFromPolicyProfile, { loading }] = useMutation(REMOVE_TARGETS_FROM_POLICY_PROFILE);

    const removeTargetFromPolicyProfile = async () => {
        console.log(`Deleting trust policy where targetId=${targetId} and profileId=${trustProfile.profileId}`);
        await removeTargetsFromPolicyProfile({
            variables: {
                tenantId: tenantId || '',
                policyProfileId: trustProfile.profileId,
                targetIds: [targetId],
            },
            refetchQueries: ['listPolicyProfiles', 'getPolicyProfile'],
        });
    };

    return (
        <div className="w-full px-4 py-2 rounded-md bg-gray-700 my-5 flex">
            <div className="flex flex-col mr-10">
                <div className="flex -ml-1">
                    <PieChart width={100} height={100}>
                        <Pie
                            data={pieSlices}
                            dataKey="value"
                            innerRadius={'75%'}
                            outerRadius={'100%'}
                            paddingAngle={0}
                            stroke="none"
                            isAnimationActive={false}
                        >
                            {pieSlices.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                            <Label
                                value={pieSlices[0].value}
                                position="center"
                                fontSize={24}
                                fontWeight="bold"
                                fill="white"
                            />
                        </Pie>
                    </PieChart>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 w-full">
                <div className="flex items-center w-full">
                    <div className="w-full flex space-x-5">
                        <div className="flex-shrink-0">
                            <img
                                className="h-12 w-12"
                                src={getIconSourceURL(getNodeIconElement(target as unknown as Node))}
                                alt=""
                            />
                        </div>
                        <div>
                            <p className="text-xs uppercase text-gray-400 font-semibold tracking-widest">Target</p>
                            <div className="text-md  text-gray-200 tracking-wide">
                                {getDisplayName(target as unknown as Node)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-full">
                        <p className="text-xs uppercase text-gray-400 font-semibold tracking-widest">Action</p>
                        <div className="text-md text-gray-300 tracking-wide">
                            {action && policyActionLookup[action]}
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-full">
                        <p className="text-xs uppercase text-gray-400 font-semibold tracking-widest">Controls</p>
                        <div className="flex space-x-2 mt-1">
                            <Tooltip label="Edit Trust Policy">
                                <button
                                    className="btn text-xs bg-gray-600 rounded-md px-2 py-1"
                                    type="submit"
                                    onClick={() =>
                                        onEdit({
                                            target,
                                            targetId,
                                            trustProfileId: trustProfile.profileId,
                                            trustProfile,
                                            threshold,
                                            action,
                                        })
                                    }
                                >
                                    <PencilIcon className="h-4 w-4" />
                                </button>
                            </Tooltip>
                            {loading ? (
                                <div className="h-4 w-4 loader" />
                            ) : (
                                <Tooltip label="Delete Trust Policy">
                                    <button
                                        className="btn text-xs bg-gray-600 rounded-md px-2 py-1"
                                        type="submit"
                                        onClick={removeTargetFromPolicyProfile}
                                    >
                                        <TrashIcon className="h-4 w-4" />
                                    </button>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
