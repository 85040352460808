import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { CREATE_USER, LIST_USERS, DELETE_USER, LIST_ROLES, UPDATE_USER } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Panel } from './Panel';
import { byPropertiesOf, classNames } from 'Utilities/utils';
import { ToastContext } from 'Map/Components/ToastContext';
import { User } from 'Types/types';
import Select from 'react-select';
import { useCurrentUser } from 'Hooks/User';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUserPermissions } from 'Utilities/UserPermissions';

interface FormData {
    name: string;
    email: string;
    roleIds: string[];
}

const UsersPanel = (): JSX.Element => {
    return (
        <Panel
            title="Users and Roles"
            subtitle="Manage how members of your organization can access SailPoint Identity Risk"
        >
            <UserList />
        </Panel>
    );
};

export default UsersPanel;

const transformName = (uglyName: string) => {
    const parts = uglyName.split(':');
    return parts[1];
};
export type RoleProps = {
    roles: { name: string; roleId: string }[];
    onChange: (val: ReadonlyArray<Role>) => void;
    selectedRoles?: ReadonlyArray<Role>;
};

export type Role = {
    name: string;
    roleId: string;
};

export const RoleList = ({ roles, onChange, selectedRoles }: RoleProps): JSX.Element => {
    return (
        <Select
            getOptionLabel={(option) => {
                return option.name;
            }}
            getOptionValue={(option) => {
                return option.roleId;
            }}
            closeMenuOnSelect={false}
            isMulti
            options={roles}
            classNamePrefix="multiselect-sm"
            className="relative z-50"
            isClearable={false}
            placeholder="Select role(s)"
            menuPlacement="bottom"
            autoFocus={false}
            onChange={onChange}
            defaultValue={selectedRoles}
        />
    );
};

export const UserList = (): JSX.Element => {
    const tenantId = useTenant();
    const client = useApolloClient();

    const { userCan } = useUserPermissions();

    const canCreateUser = userCan('create', '/user');
    const canUpdateUser = userCan('update', '/user/*');
    const canDeleteUser = userCan('delete', '/user/*');

    const { enableSingleSignOn } = useFlags();
    const { userId: loggedInUserId } = useCurrentUser();

    const { loading, error, data, refetch } = useQuery(LIST_USERS, { variables: { tenantId } });

    const { data: roleData } = useQuery(LIST_ROLES, { variables: { tenantId } });

    const [createUser, { loading: loadingCreateUser }] = useMutation(CREATE_USER);
    const [errorCreatingUser, setErrorCreatingUser] = useState('');

    const [deleteUser] = useMutation(DELETE_USER);
    const [errorDeletingUser, setErrorDeletingUser] = useState('');

    const [updateUser, { loading: loadingUpdateUser, error: errorUpdatingUser }] = useMutation(UPDATE_USER);

    const [updatingUser, setUpdatingUser] = useState<User>();

    const { dispatch } = useContext(ToastContext);

    const [sortedListUsers, setSortedListUsers] = useState<User[]>([]);

    useEffect(() => {
        if (data && !loading) {
            const tempArr: User[] = [];
            data &&
                data.listUsers.map((user: User) => {
                    tempArr.push(user);
                });
            setSortedListUsers(tempArr.sort(byPropertiesOf<User>(['name', 'email', 'userId'])));
        }
    }, [data, loading]);

    const roles = useMemo(() => {
        const tempRoles: Record<string, string> = {};
        if (roleData) {
            roleData.listRoles.map((role: { roleId: string; name: string }) => (tempRoles[role.roleId] = role.name));
        }
        return tempRoles;
    }, [roleData]);

    const renderRoles = (roleIds: string[]) => {
        const finishedRoles = roleIds.map((roleId) => {
            const roleName = roles[roleId];
            if (roleName && !roleName.includes(`${tenantId}:api`)) {
                return transformName(roleName);
            } else return null;
        });
        return finishedRoles.filter((role) => role != null).join(', ');
    };

    const listRoles = useMemo(() => {
        if (roleData) {
            const roleArr: Role[] = [];
            roleData.listRoles.map((role: { name: string; roleId: string }) => {
                const roleName = transformName(role.name);
                if (!role.name.includes(`${tenantId}:api:`)) {
                    const newRole: Role = {
                        name: roleName,
                        roleId: role.roleId,
                    };
                    roleArr.push(newRole);
                }
            });
            return roleArr;
        }
    }, [roleData, tenantId]);

    const [updatingUserRoles, updatingUserUnknownRoles] = useMemo(() => {
        const roleIds = updatingUser?.roleIds;
        const updatingUserRoles: Role[] = [];
        const updatingUserUnknownRoles: string[] = [];
        if (roleIds) {
            roleIds.map((role) => {
                const roleName = roles[role];
                if (roleName && !roleName.includes(`${tenantId}:api`)) {
                    updatingUserRoles.push({ name: transformName(roleName), roleId: role });
                } else {
                    updatingUserUnknownRoles.push(role);
                }
            });
        }
        return [updatingUserRoles, updatingUserUnknownRoles];
    }, [roles, tenantId, updatingUser?.roleIds]);

    const deleteThisUser = async (user: User) => {
        try {
            const userId = user.userId;
            await deleteUser({ variables: { tenantId, userId } });

            const users = client.readQuery({ query: LIST_USERS, variables: { tenantId } });
            const newUsers = users.listUsers.filter((user: User) => user.userId !== userId);
            client.writeQuery({
                query: LIST_USERS,
                variables: { tenantId },
                data: { listUsers: newUsers },
            });

            dispatch({
                type: 'add-toast',
                message: `Deleted user ${user.name}`,
                status: 'success',
                autoTimeout: true,
                timeoutTimer: 10,
            });
        } catch (e) {
            setErrorDeletingUser('Error deleting user');
            dispatch({
                type: 'add-toast',
                message: `Failed deleting user ${user.name}`,
                status: 'failure',
                autoTimeout: false,
            });
        }
    };

    const [createUserForm, setCreateUserForm] = useState<boolean>(false);
    const [updateForm, setUpdateForm] = useState<boolean>(false);

    //Create User Form
    const openCreateUserForm = () => {
        setCreateUserForm(true);
        setFormData({
            name: '',
            email: '',
            roleIds: [],
        });
    };

    const closeCreateUserForm = () => {
        setCreateUserForm(false);
        setFormData({} as FormData);
    };

    //Update User Form
    const openUserUpdateForm = (user: User) => {
        setUpdateForm(true);
        setUpdatingUser(user);
        setFormData({
            ...user,
        });
    };

    const closeUserUpdateForm = () => {
        setUpdateForm(false);
        setUpdatingUser(undefined);
        setFormData({} as FormData);
    };

    const [formData, setFormData] = useState<FormData>({} as FormData);
    const handleForm = (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [e.currentTarget.id]: e.currentTarget.value,
        });
    };

    const submitForm = async (e: React.FormEvent, formData: FormData) => {
        e.preventDefault();
        const newUser = {
            name: formData.name,
            email: formData.email,
            tenantId: tenantId,
            roleIds: formData.roleIds,
        };
        if (updatingUser) {
            await updateUser({
                variables: {
                    tenantId,
                    userId: updatingUser.userId,
                    userData: {
                        name: newUser.name,
                        roleIds: newUser.roleIds.concat(updatingUserUnknownRoles),
                    },
                },
            });

            refetch();
            closeUserUpdateForm();
        } else {
            try {
                const result = await createUser({
                    variables: {
                        input: newUser,
                    },
                });

                const users = client.readQuery({ query: LIST_USERS, variables: { tenantId } });
                const newUsers = [...users.listUsers, result.data.createUser];
                client.writeQuery({
                    query: LIST_USERS,
                    variables: { tenantId },
                    data: { listUsers: newUsers },
                });

                closeCreateUserForm();
                dispatch({
                    type: 'add-toast',
                    message: 'Created user ' + formData.name,
                    status: 'success',
                    autoTimeout: true,
                    timeoutTimer: 10,
                });
                setFormData({} as FormData);
            } catch (e) {
                console.log(e);
                setErrorCreatingUser('Error creating user');
                dispatch({
                    type: 'add-toast',
                    message: 'Failed creating user ' + formData.name,
                    status: 'failure',
                    autoTimeout: false,
                });
            }
        }
    };

    let content = <></>;

    if (loading) {
        content = <h4 className="text-xs text-gray-300">Loading Users...</h4>;
    } else if (error) {
        content = <h4 className="text-xs text-red-500">Could not load users. Please retry later.</h4>;
    } else {
        content = (
            <>
                <form onSubmit={(e) => submitForm(e, formData)}>
                    {errorDeletingUser && (
                        <h4 className="text-xs text-red-500">Could not delete user. Please retry later.</h4>
                    )}
                    {errorCreatingUser && (
                        <h4 className="text-xs text-red-500">Could not create user. Please retry later.</h4>
                    )}
                    {errorUpdatingUser && (
                        <h4 className="text-xs text-red-500">Could not update user. Please retry later.</h4>
                    )}
                    <table className="w-full text-left text-xs " data-test="users-table">
                        <colgroup>
                            <col className="w-3/12" />
                            <col className="w-3/12" />
                            {enableSingleSignOn && <col className="w-1/12" />}
                            <col className="w-5/12" />
                            <col className="w-1/12" />
                        </colgroup>
                        <thead className="">
                            <tr className="border-b border-gray-600">
                                <th className="py-2 px-5">Name</th>
                                <th className="py-2 px-5">Email</th>
                                {enableSingleSignOn && <th className="py-2 px-5">SSO</th>}
                                <th className="py-2 px-5">Role</th>
                                <th className=""></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                sortedListUsers.map((user: User) => {
                                    return updatingUser && !createUserForm && updatingUser == user ? (
                                        <tr key={'Update User'} className="mt-2 text-gray-300">
                                            <td className="pt-3 h-4 w-1/6 px-3">
                                                <input
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    placeholder="Name..."
                                                    autoComplete="name"
                                                    required
                                                    className="input-gray text-xs rounded-none"
                                                    minLength={3}
                                                    onChange={handleForm}
                                                    defaultValue={updatingUser ? updatingUser.name : ''}
                                                />
                                            </td>
                                            <td className="pt-3 h-4 w-1/6 px-3">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    placeholder="Email..."
                                                    autoComplete="email"
                                                    required
                                                    className={classNames(
                                                        'input-gray text-xs rounded-none',
                                                        updatingUser ? 'text-gray-500' : '',
                                                    )}
                                                    onChange={handleForm}
                                                    disabled={updatingUser ? true : false}
                                                    defaultValue={updatingUser ? updatingUser?.email : ''}
                                                />
                                            </td>
                                            {enableSingleSignOn && (
                                                <td className="pt-2 px-5">
                                                    {user.userId.includes('samlp') ? (
                                                        <CheckIcon className="h-4 w-4" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </td>
                                            )}
                                            <td className="pt-3 h-4 w-1/6 px-3">
                                                <RoleList
                                                    onChange={(val) =>
                                                        setFormData({
                                                            ...formData,
                                                            roleIds: val.map((v) => v.roleId),
                                                        })
                                                    }
                                                    roles={roleData && listRoles}
                                                    selectedRoles={updatingUserRoles}
                                                />
                                            </td>

                                            <td className="pt-3 text-center align-middle">
                                                {loadingUpdateUser ? (
                                                    <div className="loader h-4 w-4 my-2" />
                                                ) : (
                                                    <button
                                                        className="py-1 blue-focus-1 ring-offset-1 ring-offset-blue-700 active:text-blue-700"
                                                        type="submit"
                                                        data-test="update-user"
                                                    >
                                                        Save
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ) : (
                                        <tr key={user.userId} className="mt-2 text-gray-300">
                                            <td className="px-5">{user.name} </td>
                                            <td className="px-5">{user.email}</td>
                                            {enableSingleSignOn && (
                                                <td className="px-5">
                                                    {user.userId.includes('samlp') ? (
                                                        <CheckIcon className="h-4 w-4" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </td>
                                            )}
                                            <td className="px-5">{renderRoles(user.roleIds)}</td>
                                            <td className="flex h-6">
                                                {canUpdateUser && !createUserForm && !updateForm && (
                                                    <button
                                                        className="cursor-pointer py-1 blue-focus-1 ring-offset-1 ring-offset-blue-700 active:text-blue-700 mr-2"
                                                        onClick={() => {
                                                            openUserUpdateForm(user);
                                                        }}
                                                        data-test="edit-user"
                                                    >
                                                        Edit
                                                    </button>
                                                )}

                                                {canDeleteUser && (
                                                    <button
                                                        type="button"
                                                        className={
                                                            user.userId == loggedInUserId
                                                                ? 'text-gray-700'
                                                                : 'cursor-pointer py-1 blue-focus-1 ring-offset-1 ring-offset-blue-700 active:text-blue-700'
                                                        }
                                                        onClick={() => deleteThisUser(user)}
                                                        data-test="delete-user"
                                                        disabled={user.userId == loggedInUserId ? true : false}
                                                        title={
                                                            user.userId == loggedInUserId
                                                                ? 'You cannot delete yourself'
                                                                : ''
                                                        }
                                                    >
                                                        Delete
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            {createUserForm && !updateForm && (
                                <tr key={'Add New'} className="mt-2 text-gray-300">
                                    <td className="pt-3 h-4 w-1/6 px-3">
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            placeholder="Name..."
                                            autoComplete="name"
                                            required
                                            className="input-gray text-xs rounded-none"
                                            minLength={3}
                                            onChange={handleForm}
                                        />
                                    </td>
                                    <td className="pt-3 h-4 w-1/6 px-3">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder="Email..."
                                            autoComplete="email"
                                            required
                                            className="input-gray text-xs rounded-none"
                                            onChange={handleForm}
                                        />
                                    </td>
                                    {enableSingleSignOn && <td></td>}
                                    <td className="pt-3 h-4 w-full px-3">
                                        <RoleList
                                            onChange={(val) =>
                                                setFormData({
                                                    ...formData,
                                                    roleIds: val.map((v) => v.roleId),
                                                })
                                            }
                                            roles={roleData && listRoles}
                                        />
                                    </td>
                                    <td className="pt-3 text-center align-middle">
                                        {loadingCreateUser ? (
                                            <div className="loader h-4 w-4 my-2" />
                                        ) : (
                                            <button
                                                className="py-1 blue-focus-1 ring-offset-1 ring-offset-blue-700 active:text-blue-700"
                                                type="submit"
                                                data-test="save-user-button"
                                            >
                                                Save
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </form>
                {canCreateUser && (
                    <button
                        id="UsersButton"
                        data-test="users-button"
                        className="btn text-xs mt-4 ml-4"
                        onClick={() => {
                            if (updateForm) {
                                closeUserUpdateForm();
                            } else if (createUserForm) {
                                closeCreateUserForm();
                            } else if (!createUserForm) {
                                openCreateUserForm();
                            }
                        }}
                    >
                        {createUserForm || updateForm ? 'Cancel' : 'Add New User'}
                    </button>
                )}
            </>
        );
    }
    return content;
};
